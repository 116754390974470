import React from 'react';

const Cookies = () => {
    return ( 

        <div>
            <h1>Cookies</h1>
            <p>Cookies</p>
        </div>
     );
}
 
export default Cookies;