import { useTranslation } from 'react-i18next';

const useLanguage = () => {
    const { t, i18n } = useTranslation();
    const curPath = window.location.pathname;
    const activeLanguage = curPath.split('/')[1];

    return {
        t,
        i18n,
        curPath,
        activeLanguage,
    }
}

export default useLanguage