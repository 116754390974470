import React from 'react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from 'App';
import { store } from 'redux/store';
import 'index.css';

import ScrollToTop from 'components/ScrollToTop';

import './i18n';

import ReactGA from 'react-ga4';
const TRACKING_ID = "GTM-PNMGBJ6F";
ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//set background color
document.body.style.backgroundColor = "#F7F7F7";

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <CssBaseline />
      <ScrollToTop />
      <App />
    </Provider>
  </BrowserRouter>,
);