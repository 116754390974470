import React, { useEffect } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";

// import VehicleFilterAccordeon from 'components/common/VehicleFilterAccordeon';
import VehicleFilterAccordeon from "components/common/VehicleFilterAccordeon";

import useMeta from "components/useMeta";

import AanbodGrid from "components/common/AanbodGrid";
import useLanguage from "hooks/useLanguage";
import { MoveFadeInAnimation } from "hooks/useAnimations";

type Props = any;
const Aanbod = (props: Props) => {
    const { t } = useLanguage();

    function OpenMobileFilter() {
        document.querySelector(".mobile-filter")?.classList.add("active");
    }
    
    function CloseMobileFilter() {
        document.querySelector(".mobile-filter")?.classList.remove("active");
    }

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth > 899) {
                // remove mobile-filter from filter-wrapper class
                document.querySelector(".filter-wrapper")?.classList.remove("mobile-filter");
                document.querySelector(".mobile-filter")?.classList.remove("active");
            }
            else {
                document.querySelector(".filter-wrapper")?.classList.add("mobile-filter");
            }
        }
        handleResize();

        window.addEventListener('resize', handleResize)
    }, [])

    
    return (
        useMeta({
            title: 'Kegels Turnhout - ' + t("Offer"),
            description: t("All Cars"),
            keywords: '',
        }),
        <React.Fragment>
            <div className="mx-auto max-w-screen-xl" >
                <Grid className="my-10" container spacing={2} columns={12}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Box className="filter-wrapper mobile-filter slideDown">
                            <div className="close absolute top-6 right-6 aspect-square text-4xl leading-3 p-2 cursor-pointer z-10" onClick={CloseMobileFilter}>
                                &times;
                            </div>
                            <div className="pl-0 md:pl-4 z-0">
                                <Typography variant="h6" className="leading-3 pb-0 sticky">
                                    {t("Filters")}
                                </Typography>
                                <div className="h-full overflow-auto pb-20">
                                    <VehicleFilterAccordeon allowMultiple={false} />
                                </div>    
                            </div>

                            <Button variant="contained" className="block md:hidden lg:hidden w-full fixed left-0 rounded-none text-lg p-3 bottom-0 normal-case" onClick={CloseMobileFilter}>
                                {t("Apply filter(s)")}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <AanbodGrid id={1} />
                    </Grid>
                </Grid>
            </div>
            <section className="w-full fixed bottom-0 z-[999] block md:hidden lg:hidden">
                <Button variant="contained" color="primary" className="w-full fixed left-0 rounded-none text-lg font-normal p-3 bottom-0 normal-case" onClick={OpenMobileFilter}>
                    Filter
                </Button>
            </section>

            <section className="bg-white py-8">
                <Container>
                    <Grid container spacing={3} className="my-20 flex items-center justify-center text-center">
                        <Grid item xs={12} sm={12} md={10} lg={8}>
                            <MoveFadeInAnimation delay={300}>
                                <Typography variant="h6" className="">
                                    {t("Can't find your dream car in this list or are you looking for a completely new (and therefore still to be configured) car? Do not hesitate to contact us for an appointment and an à la carte approach!")}
                                </Typography>
                            </MoveFadeInAnimation>
                        </Grid>
                        <Grid item xs={12} className="lg:text-right">
                            <div className="flex gap-8 justify-center">
                                <Button variant="contained" href={`mailto:info@kegelsturnhout.be`} >
                                    {t("Email us")}
                                </Button>
                                <Button variant="contained" href={`tel:3214450311`} >
                                    {t("Call us")}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default Aanbod;