import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';

import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Grid from '@mui/material';

function PrivacyPolicy() {
  const { t } = useTranslation();



  return (
    <Container className='py-10'>
        <Typography variant="h1">Privacy beleid</Typography>
        <Typography variant="body1">Dit Europese privacy beleid voor de aankoop van Stellantis Voertuigen (&quot;Privacy beleid&quot;), is van toepassing op onze verzameling en verwerking van Persoonsgegevens met betrekking tot wie:</Typography>
        <List>
            <ListItem>· een Voertuig van ons heeft gekocht of gaat kopen/leasen of huren; of</ListItem>
            <ListItem>· ons om een offerte voor een Voertuig of wagenpark heeft gevraagd; of</ListItem>
            <ListItem>· ons om een proefrit van een Voertuig heeft gevraagd; of</ListItem>
            <ListItem>· ons om assistentie, een reparatie of een andere dienst voor een Voertuig verzocht.</ListItem>
        </List>
        <Typography variant="body1" gutterBottom>Dit Privacy beleid is opgesteld ingevolge artikel 13 van de EU-verordening 679/2016 (hierna &quot;AVG&quot;) en helpt u beter te begrijpen hoe wij met uw Gegevens omgaan. U kunt meer informatie vinden over hoe wij uw Persoonsgegevens verzamelen en verwerken op onze maatschappelijke zetel.</Typography>
        
        <Typography variant="h4">1. Wie we zijn</Typography>
        <Typography variant="body1" gutterBottom>Wij zijn Kegels NV (&quot;wij&quot; of &quot;ons&quot;), Gegevensbeheerder gevestigd Ambachtstraat 2, Turnhout, (Antwerpen) 2275 - België. Indien u bijkomende uitleg wenst over dit Privacy beleid of over de manier waarop uw Gegevens worden verwerkt, kunt u uw verzoek sturen naar: info@kegelsturnhout.be</Typography>
        
        <Typography variant="h4">2. Welke gegevens we verzamelen en verwerken</Typography>
        <Typography variant="body1">Wanneer u onze vestigingen bezoekt, kunt u een proefrit aanvragen, een offerte vragen voor een Voertuig of wagenpark, een aankoop doen, assistentie of reparaties vragen, of aangeven dat  een eerder gekocht Voertuig van eigenaar is veranderd. Afhankelijk van het geval kunnen wij uw Persoonsgegevens rechtstreeks en/of via de Autofabrikant of Stellantis Europe S.p.A. (“Stellantis Europe”) verzamelen.</Typography>
        <Typography variant="body1">Meer details over de redenen waarom wij uw Persoonsgegevens verwerken, vindt u in het gedeelte &quot;Waarom wij uw Gegevens verzamelen en verwerken&quot; hieronder. Het verstrekken van uw Persoonsgegevens is altijd gratis en zonder gevolgen, behalve voor het nastreven van bepaalde doeleinden. Indien bepaalde informatie en/of Gegevens verplicht zijn, geven wij dit aan in onze formulieren en documenten.</Typography>
        <Typography variant="body1">a) Door u verstrekte gegevens</Typography>
        <Typography variant="body1">Wij verzamelen Persoonsgegevens, hetzij direct van u (bijv. via een door ons verstrekte tablet of formulieren), hetzij indirect via door u gevraagde ondersteuning. Dit zijn meestal contactgegevens en identificatiegegevens, zoals uw voornaam, naam, geboortedatum, e-mailadres, woonplaats, telefoonnummer, beroep en voorkeurstaal, bankgegevens, fiscaal nummer, factuurgegevens, of u een financieringsaanvraag indient, en of u al klant bent bij ons of bij andere fabrikanten. Sommige van deze Gegevens (bijvoorbeeld uw contactgegevens) zijn essentieel voor ons om uw aanvragen te beheren; andere Gegevens (bijvoorbeeld beroep, geboortedatum) kunnen optioneel zijn, maar helpen ons om u beter te leren kennen.</Typography>
        <Typography variant="body1">Soms kunnen de door u verstrekte Gegevens betrekking hebben op derden. Als u ons de Gegevens van derden verstrekt, bent u verantwoordelijk voor het delen van die Gegevens met ons en moet u daartoe wettelijk bevoegd zijn (d.w.z. u bent door de derde partij gemachtigd om hun Gegevens te delen, of het is noodzakelijk en om een legitieme reden). U moet ons ook volledig vrijwaren tegen klachten, vorderingen of eisen tot schadevergoeding die kunnen voortvloeien uit de verwerking van Persoonsgegevens van derden die in strijd zijn met de toepasselijke wetgeving inzake gegevensbescherming.</Typography>
        <Typography variant="body1">b) Uw voertuiggegevens</Typography>
        <Typography variant="body1">Wanneer u een Voertuig koopt, worden de Gegevens die het Voertuig identificeren (bijvoorbeeld het Voertuigidentificatienummer of VIN, het kentekennummer, enz.) samen met de Voertuiggegevens gekoppeld aan uw Persoonsgegevens, waardoor het een van uw Unieke Identificatoren wordt. Wij hebben deze Gegevens nodig om de Diensten aan eigenaars te kunnen leveren, om aan wettelijke verplichtingen te voldoen en om de belangen van klanten en van henzelf te waarborgen.</Typography>
        <Typography variant="body1">c) Gegevens afgeleid uit uw activiteit</Typography>
        <Typography variant="body1">Voor zover toegestaan onder de toepasselijke wetgeving inzake gegevensbescherming, kunnen wij verdere informatie over u verzamelen op basis van uw interacties met ons. Wij kunnen bijvoorbeeld zien dat u de snelheid of het comfort van een bepaald model dat u voor een proefrit hebt genomen, op prijs stelt; of u al dan niet van onze Diensten hebt genoten of dat u op de hoogte bent van het onderhoud van uw Voertuig of een herinnering nodig hebt.</Typography>
        <Typography variant="body1" gutterBottom>In sommige gevallen, als u per e-mail, post, telefoon of anderszins contact met ons opneemt over het Voertuig of andere informatie vraagt, verzamelen en bewaren wij uw contactgegevens, mededelingen en onze antwoorden. Als u telefonisch contact met ons opneemt, wordt tijdens het gesprek meer informatie verstrekt.</Typography>

        <Typography variant="h4">3. Bron van Persoonsgegevens</Typography>
        <Typography variant="body1">a) Gegevens ontvangen van derden binnen de Stellantis Groep</Typography>
        <Typography variant="body1">Voor zover toegestaan onder de toepasselijke wetgeving inzake gegevensbescherming, kunnen wij verzamelen:</Typography>
        <Typography variant="body1">-       Gegevens die door u aan Autofabrikanten of Stellantis Europe zijn verstrekt. Dit is het geval wanneer u ons meedeelt dat u reeds klant bent bij één of meer Autofabrikanten en u het proces van verzameling en verbetering van de kwaliteit en correctheid van de Persoonsgegevens die u eerder hebt verstrekt, wenst te versnellen;</Typography>
        <Typography variant="body1">-       Door u aan de Autofabrikanten van uw Voertuig verstrekte Gegevens vanwege de geldigheid van specifieke door hen verleende diensten (bv. wegenwacht, reparaties in verband met productaansprakelijkheid, enz;)  </Typography>
        <Typography variant="body1" gutterBottom>Feedback die u verstrekt aan Autofabrikanten of Stellantis Europe naar aanleiding van de levering van een van onze Diensten (bijv. feedback na verkoop en nazorg). Zie het privacy beleid van Autofabrikanten en Stellantis Europe voor meer informatie over hun verwerkingsactiviteiten.</Typography>
        
        <Typography variant="h4">4. Waarom wij uw Gegevens verzamelen en verwerken en wettelijke basis</Typography>
        <Typography variant="body1">Uw Gegevens dienen de volgende doeleinden:</Typography>
        <Typography variant="body1">a)       Het verzamelen en corrigeren van uw gegevens vergemakkelijken</Typography>
        <Typography variant="body1">Wij gebruiken de door u verstrekte Gegevens (met name de informatie dat u reeds klant bent bij een of meer Autofabrikanten) om het verzamelen van uw Gegevens te vergemakkelijken/versoepelen. In deze gevallen zullen wij uw toestemming vragen om de databases van Stellantis Europe te bevragen om het verzamelen te vergemakkelijken en u in staat te stellen de beschikbare informatie bij te werken of te corrigeren. </Typography>
        <Typography variant="body1">Deze verwerking is gebaseerd op de uitvoering van een contractuele verplichting of op precontractuele maatregelen die op uw verzoek zijn genomen om het registratieproces te versnellen.</Typography>
        <Typography variant="body1">b)       Het verlenen van onze diensten en gerelateerde ondersteuning</Typography>
        <Typography variant="body1">Wij gebruiken uw Gegevens om u Diensten aan te bieden, waaronder de offerte voor een Voertuig, proefritten, de verkoop, leasing of huur van Voertuigen; serviceberichten voor controles en onderhoud van het Voertuig; indien beschikbaar en geactiveerd, de door de Autofabrikant aangeboden technische hulpdienst; hulp bij de eigendomsoverdracht, en om te reageren op uw verzoeken/suggesties/meldingen. Dit doel omvat de communicatie van de Gegevens die nodig zijn voor de Autofabrikant om wettelijke garanties op het Voertuig te verlenen.</Typography>
        <Typography variant="body1">Deze verwerking is gebaseerd op de uitvoering van een contractuele verplichting of precontractuele maatregelen die op uw verzoek zijn genomen.  </Typography>
        <Typography variant="body1">c)       U promotionele berichten sturen</Typography>
        <Typography variant="body1">Wij kunnen uw contactgegevens gebruiken (e-mail, telefoon, SMS en/of andere beschikbare middelen) voor het verzenden van promotionele communicatie of voor marktonderzoek en consumentenonderzoeken met marketinginhoud.</Typography>
        <Typography variant="body1">Deze verwerking is gebaseerd op uw voorafgaande toestemming.</Typography>
        <Typography variant="body1">d)       Het analyseren van uw voorkeuren en gedrag om onze diensten en communicatie aan te passen, met inbegrip van Inhoud die nuttig voor u kan zijn.</Typography>
        <Typography variant="body1">Wij gebruiken uw Gegevens, in het bijzonder Gegevens afgeleid uit uw activiteiten, Voertuiggegevens en Geaggregeerde Informatie dus om onze promotionele communicatie, commerciële initiatieven en digitale platforms te verbeteren.</Typography>
        <Typography variant="body1">Aangepaste communicatie is gebaseerd op uw gedrag, interesses, behoeften, voorkeuren en profiel; dergelijke doeleinden kunnen ook worden bereikt op basis van Persoonsgegevens die worden verzameld door het gebruik van cookies of andere traceertechnologieën om de voorkeuren van de klant te analyseren en te voorspellen en de klant aanbiedingen op maat te doen.</Typography>
        <Typography variant="body1">Deze verwerking is gebaseerd op uw voorafgaande toestemming.</Typography>
        <Typography variant="body1">Als u geen gepersonaliseerde communicatie wenst, kunt u uw voorkeuren wijzigen, zoals uitgelegd in het gedeelte &quot;Hoe uw gegevens controleren en uw keuzes beheren&quot; hieronder.</Typography>
        <Typography variant="body1">e)       Het delen van gegevens met partners voor hun eigen marketingdoeleinden</Typography>
        <Typography variant="body1">Wij delen uw contactgegevens met derde geselecteerde Partners (zoals hieronder gedefinieerd) voor hun eigen autonome marketingdoeleinden. De Partners zullen alleen via geautomatiseerde middelen (bijv. e-mail, SMS, telefoonoproep) contact met u opnemen. Deze verwerking is gebaseerd op uw voorafgaande toestemming. U kunt de volledige lijst of categorieën van Partners met wie wij uw Gegevens hebben gedeeld opvragen bij: info@kegelsturnhout.be</Typography>
        <Typography variant="body1">f)       Voertuiggegevens delen met de autofabrikant</Typography>
        <Typography variant="body1">Wij delen Voertuiggegevens die tijdens de levering van een van onze Diensten (bijvoorbeeld een onderhoud) zijn verzameld met de Autofabrikant om deze laatste in staat te stellen te voldoen aan de Europese Verordening (EU) 2021/392. Raadpleeg het privacy beleid van uw Autofabrikant voor meer informatie over deze verwerkingsactiviteit.</Typography>
        <Typography variant="body1">Deze verwerking is noodzakelijk voor de uitvoering van een taak in het algemeen belang die ons door de Europese wetgeving is opgelegd. Toch kunt u tegen deze uitwisseling bezwaar maken/ deze uitschakelen wanneer u onze gebouwen bezoekt, zoals uitgelegd in het gedeelte &quot;Hoe kunt u uw gegevens controleren en uw keuzes beheren&quot; hieronder.</Typography>
        <Typography variant="body1">g)       Voertuiggegevens delen met Stellantis Europe</Typography>
        <Typography variant="body1">Wij delen Voertuiggegevens die tijdens de levering van een van onze Diensten (bijv. een onderhoud) zijn verzameld met Stellantis Europe om deze laatste in staat te stellen Voertuigen te verbeteren, de effectiviteit van hun Diensten te meten en nieuwe Diensten te creëren. Voertuiggegevens worden door Stellantis Europe verwerkt als Persoonsgegevens en/of als Geaggregeerde Informatie. Raadpleeg het privacy beleid van Stellantis Europe voor meer informatie over deze verwerking.</Typography>
        <Typography variant="body1">Deze verwerking is gebaseerd op ons en Stellantis Europe&apos;s gerechtvaardigd belang om Voertuigen en Diensten te creëren en te onderhouden die werkelijk nuttig zijn voor onze klanten.</Typography>
        <Typography variant="body1">h)       Delen van door u verstrekte Gegevens met Stellantis Europe</Typography>
        <Typography variant="body1">Wij kunnen door u verstrekte gegevens (met name contactgegevens) delen met Stellantis Europe om de juistheid en kwaliteit van uw gegevens te verbeteren/garanderen. </Typography>
        <Typography variant="body1">Zie het privacybeleid van Stellantis Europe voor meer informatie over deze verwerking.</Typography>
        <Typography variant="body1">Deze verwerking is gebaseerd op het legitieme belang van Stellantis Europe, de Autofabrikant en ons om de inspanning voor u om Persoonsgegevens te verstrekken en om correcte en op elkaar afgestemde Persoonsgegevens in hun databases te hebben, te versnellen en te verminderen.</Typography>
        <Typography variant="body1">i)     Voldoen aan wettelijke en fiscale verplichtingen</Typography>
        <Typography variant="body1">Wij kunnen uw Gegevens gebruiken om te voldoen aan wettelijke en fiscale verplichtingen waaraan wij zijn onderworpen en die de rechtsgrondslag vormen voor de verwerking van uw Gegevens. Sommige wetgevingen kunnen ons verplichten uw Gegevens te delen met overheidsinstanties (bijv. terugroepberichten). Als dit delen niet wettelijk verplicht is in uw land, sturen wij ze toch door zoals nader uitgelegd in het gedeelte &quot;Bescherming van onze belangen en uw belangen&quot; hieronder.</Typography>
        <Typography variant="body1">j)     Bescherming van onze en uw belangen</Typography>
        <Typography variant="body1">Voor zover toegestaan onder de toepasselijke wetgeving inzake gegevensbescherming, moeten wij uw Gegevens mogelijk gebruiken om frauduleus en illegaal gedrag of activiteiten die uw of onze veiligheid in gevaar kunnen brengen, op te sporen, erop te reageren en te voorkomen. Dit doel omvat audits en beoordelingen van onze bedrijfsactiviteiten, veiligheidscontroles, financiële controles, administratie- en informatiebeheerprogramma&apos;s, en anderszins met betrekking tot de administratie van onze algemene bedrijfs-, boekhoud-, administratie- en juridische functies. Wij zullen uw Gegevens ook gebruiken om u mededelingen te sturen over de veiligheid van uw Voertuig/vloot (bijv. terugroepacties, software-updates, enz.), zelfs als er in het land waar u zich bevindt geen wettelijke verplichting daartoe bestaat. Dit zijn geen promotionele berichten, maar serviceberichten om uw veiligheid bij het gebruik van uw Voertuig te waarborgen.</Typography>
        <Typography variant="body1" gutterBottom>Dit doel is gebaseerd op het gerechtvaardigd belang om onze belangen veilig te stellen en onze klanten, waaronder u, te beschermen.  </Typography>
        
        <Typography variant="h4">5. Hoe wij uw gegevens gebruiken (wijze van verwerking)</Typography>
        <Typography variant="body1" gutterBottom>De voor de bovengenoemde doeleinden verzamelde gegevens worden zowel handmatig als via geautomatiseerde verwerking verwerkt. Er is geen sprake van volledig geautomatiseerde verwerking.</Typography>
        
        <Typography variant="h4">6. Hoe wij uw gegevens openbaar kunnen maken</Typography>
        <Typography variant="body1">Wij kunnen uw Gegevens bekendmaken aan de volgende ontvangers en/of categorieën ontvangers (&quot;ontvangers&quot;):</Typography>
        <Typography variant="body1">-       Personen die door ons gemachtigd zijn om de in dit document beschreven gegevens gerelateerde activiteiten uit te voeren: onze werknemers en medewerkers die een geheimhoudingsplicht hebben en zich houden aan specifieke regels betreffende de verwerking van uw Gegevens;</Typography>
        <Typography variant="body1">-       Onze Gegevensverwerkers: externe entiteiten aan wie wij bepaalde verwerkingsactiviteiten delegeren. Bijvoorbeeld leveranciers van beveiligingssystemen, boekhouders en andere consultants, providers van datahosting, bank, verzekering, enz. Wij hebben met elk van onze Gegevensverwerkers overeenkomsten gesloten om te waarborgen dat uw Gegevens met passende waarborgen en uitsluitend in onze opdracht worden verwerkt;</Typography>
        <Typography variant="body1">-       Systeembeheerders: onze werknemers of die van Gegevensverwerkers aan wie wij het beheer van onze IT-systemen hebben gedelegeerd en die daarom in staat zijn de verwerking van uw gegevens in te zien, te wijzigen, op te schorten of te beperken. Deze personen zijn geselecteerd, adequaat opgeleid en hun activiteiten worden gevolgd door systemen die zij niet kunnen wijzigen, zoals bepaald in de bepalingen van de betrokken Toezichthoudende Autoriteit;</Typography>
        <Typography variant="body1">-       De Autofabrikant: zodat deze kan voldoen aan de Europese verordening (EU) 2021/392.</Typography>
        <Typography variant="body1">-       Stellantis Europe: dit is het geval wanneer wij Voertuiggegevens als Persoonsgegevens en/of Geaggregeerde Informatie overdragen om deze laatste in staat te stellen Voertuigen te verbeteren, de effectiviteit van hun Diensten te meten en nieuwe Diensten te creëren; en, wanneer wij door u verstrekte Gegevens delen om het verzamelen en corrigeren van uw Gegevens te vergemakkelijken;</Typography>
        <Typography variant="body1" gutterBottom>-       Rechtshandhaving of enige andere autoriteit waarvan de bepalingen bindend zijn voor ons: dit is het geval wanneer wij moeten voldoen aan een gerechtelijk bevel of wet of ons moeten verdedigen in gerechtelijke procedures.</Typography>
        
        <Typography variant="h4">7. Waar uw gegevens zich bevinden</Typography>
        <Typography variant="body1" gutterBottom>Uw Gegevens kunnen worden opgeslagen, geraadpleegd, gebruikt, verwerkt en bekendgemaakt buiten uw rechtsgebied, inclusief binnen de Europese Unie, de Verenigde Staten van Amerika of enig ander land waar onze Gegevensverwerkers en sub-verwerkers zijn gevestigd, of waar hun servers of cloud computing-infrastructuren kunnen worden gehost. Wij nemen maatregelen om ervoor te zorgen dat de verwerking van uw Gegevens door onze Ontvangers in overeenstemming is met de toepasselijke wetgeving inzake gegevensbescherming, met inbegrip van de EU-wetgeving waaraan wij onderworpen zijn. Indien vereist door de EU-wetgeving inzake gegevensbescherming, is de overdracht van uw Gegevens aan Ontvangers buiten de EU onderworpen aan passende waarborgen (zoals de relevante standaardcontractbepalingen van de EU voor gegevensoverdracht tussen EU- en niet-EU-landen), en/of een andere rechtsgrondslag overeenkomstig de EU-wetgeving. Voor meer informatie over de passende waarborgen die wij hebben ingevoerd met betrekking tot Gegevens die worden doorgegeven aan derde landen, kunt u ons schrijven op het volgende adres: info@kegelsturnhout.be</Typography>
        
        <Typography variant="h4">8. Hoe lang we uw gegevens bewaren</Typography>
        <Typography variant="body1" gutterBottom>De Gegevens die voor de hierboven aangegeven doeleinden worden verwerkt, worden bewaard gedurende de periode die strikt noodzakelijk wordt geacht om deze doeleinden te verwezenlijken. Gegevens die worden verwerkt om te voldoen aan de wettelijke verplichtingen waaraan wij onderworpen zijn, worden bewaard gedurende de periode die de wet voorschrijft. Persoonsgegevens die worden verwerkt om onze belangen en die van onze gebruikers te beschermen, worden bewaard tot de tijd die de toepasselijke lokale wetgeving voorschrijft om onze belangen te beschermen. Zodra de relevante bewaartermijn/criterium is verstreken, worden uw Gegevens gewist overeenkomstig ons bewaarbeleid. U kunt ons meer informatie vragen over onze criteria en ons beleid inzake gegevensbewaring door ons hier aan te schrijven: info@kegelsturnhout.be</Typography>
        
        <Typography variant="h4">9. Hoe u uw gegevens kunt controleren en uw keuzes kunt beheren</Typography>
        <Typography variant="body1">U kunt op elk moment vragen om:</Typography>
        <Typography variant="body1">-       Toegang tot uw gegevens (recht op toegang): afhankelijk van uw interacties met ons, zullen wij de Gegevens die wij over u hebben, zoals uw naam, leeftijd, e-mailadres en voorkeuren ter beschikking stellen.</Typography>
        <Typography variant="body1">-       Uitoefening van uw recht op overdraagbaarheid van uw Persoonsgegevens (recht op gegevensoverdraagbaarheid): indien van toepassing zullen wij u een interoperabel bestand verstrekken met de Gegevens die wij over u hebben.</Typography>
        <Typography variant="body1">-       Uw gegevens corrigeren (recht op rectificatie): u kunt ons bijvoorbeeld vragen uw e-mailadres of telefoonnummer te wijzigen als deze onjuist zijn;</Typography>
        <Typography variant="body1">-       De verwerking van uw gegevens beperken (recht op beperking van de verwerking): bijvoorbeeld wanneer u van mening bent dat de verwerking van uw Gegevens onwettig is of dat verwerking op basis van ons gerechtvaardigd belang niet passend is;</Typography>
        <Typography variant="body1">-       Uw gegevens wissen (recht op wissen): bijvoorbeeld als u niet wilt dat wij uw Gegevens bewaren en er geen andere reden is om ze te bewaren (bijv. als u niet langer de eigenaar van het Voertuig bent en niet met ons in contact wilt blijven);</Typography>
        <Typography variant="body1">-       Bezwaar maken tegen de verwerking gedefinieerd in de Europese Verordening (EU) 2021/392 (recht op bezwaar): hoewel dit niet het recht is dat in artikel 21 van Verordening 2016/679 staat, heeft u een specifiek recht om bezwaar te maken tegen het delen van de Voertuiggegevens met de Autofabrikant;</Typography>
        <Typography variant="body1" gutterBottom>-       Uw toestemming intrekken (herroepingsrecht): u kunt een van de bovenstaande rechten uitoefenen, uw bezorgdheid uiten of een klacht indienen over ons gebruik van uw Gegevens door te schrijven naar: info@kegelsturnhout.be</Typography>
        
        <Typography variant="h4">Op elk moment kunt u ook:</Typography>
        <Typography variant="body1">-       Hier contact opnemen met onze functionaris voor gegevensbescherming: info@kegelsturnhout.be </Typography>
        <Typography variant="body1" gutterBottom>-       contact opnemen met de toezichthoudende autoriteit, waarvan u hier de lijst vindt van alle toezichthoudende autoriteiten per land https://edpb.europa.eu/about-edpb/board/members_nl</Typography>
        
        <Typography variant="h4">10. Hoe wij uw gegevens beschermen</Typography>
        <Typography variant="body1">Wij nemen redelijke voorzorgsmaatregelen vanuit fysiek, technologisch en organisatorisch oogpunt om verlies, misbruik of wijziging van Gegevens onder onze controle te voorkomen. Bijvoorbeeld:</Typography>
        <Typography variant="body1">-       Wij zorgen ervoor dat uw Gegevens alleen worden ingezien en gebruikt door, overgedragen aan of bekendgemaakt aan ontvangers die toegang moeten hebben tot deze Gegevens.</Typography>
        <Typography variant="body1">-       Wij beperken ook de hoeveelheid Gegevens die toegankelijk is, worden doorgegeven of bekendgemaakt aan ontvangers tot alleen datgene wat nodig is om de doeleinden of specifieke taken van de ontvanger te vervullen.</Typography>
        <Typography variant="body1">-       De computers en servers waarop uw Gegevens worden opgeslagen, bevinden zich in een beveiligde omgeving, worden met een wachtwoord gecontroleerd en hebben beperkte toegang, en hebben standaard firewalls en antivirussoftware geïnstalleerd.</Typography>
        <Typography variant="body1">-       Papieren kopieën van eventuele documenten met uw Gegevens worden eveneens in een beveiligde omgeving bewaard.</Typography>
        <Typography variant="body1">-       Wij vernietigen papieren kopieën van documenten met uw Gegevens die niet langer nodig zijn.</Typography>
        <Typography variant="body1">-       Bij de vernietiging van Gegevens die in de vorm van elektronische bestanden zijn vastgelegd en opgeslagen en die niet langer nodig zijn, zorgen wij ervoor dat een technische methode (bijvoorbeeld een laag formaat) garandeert dat de Gegevens niet kunnen worden gereproduceerd.</Typography>
        <Typography variant="body1">-       Laptops, USB-sleutels, mobiele telefoons en andere elektronische draadloze apparaten die worden gebruikt door onze werknemers die toegang hebben tot uw Gegevens, worden beschermd. Wij moedigen werknemers aan om uw Gegevens niet op dergelijke apparaten op te slaan, tenzij dit redelijkerwijs noodzakelijk is om een specifieke taak uit te voeren zoals uiteengezet in dit Privacy beleid.</Typography>
        <Typography variant="body1">-       Wij leiden onze werknemers op om dit privacy beleid na te leven en voeren controleactiviteiten uit om een voortdurende naleving te waarborgen en de doeltreffendheid van ons privacybeheer te bepalen.</Typography>
        <Typography variant="body1">-       Elke Gegevensverwerker die wij gebruiken is contractueel verplicht om uw Gegevens bij te houden en te beschermen met maatregelen die in grote lijnen overeenkomen met de maatregelen in dit Privacy beleid of die vereist zijn volgens de toepasselijke wetgeving inzake gegevensbescherming.</Typography>
        <Typography variant="body1" gutterBottom>Indien de toepasselijke wetgeving dit vereist, wordt u en de bevoegde gegevensbeschermingsautoriteit in kennis gesteld van een inbreuk op de beveiliging die leidt tot een accidentele of onwettige vernietiging, verlies, wijziging of ongeoorloofde bekendmaking van of toegang tot doorgezonden, opgeslagen of anderszins verwerkte Gegevens (bijvoorbeeld tenzij de Gegevens voor niemand begrijpelijk zijn of de inbreuk waarschijnlijk geen risico inhoudt voor uw rechten en vrijheden en die van anderen).</Typography>
        
        <Typography variant="h4">11.  Wat dit privacy beleid niet dekt</Typography>
        <Typography variant="body1">Dit privacy beleid verklaart en dekt de verwerking die wij uitvoeren als Gegevensverantwoordelijke</Typography>
        <Typography variant="body1">Dit Privacy beleid is niet van toepassing op verwerkingen door anderen dan ons, met inbegrip van en in het bijzonder:</Typography>
        <Typography variant="body1">-         verwerking uitgevoerd door Stellantis Europe S.p.A. als enige Gegevensverantwoordelijke of door Autofabrikanten (d.w.z. Stellantis Europe S.p.A, PSA Automobiles S.A en Opel Automobile GmbH treden op als onafhankelijke Gegevensverantwoordelijke</Typography>
        <Typography variant="body1">-         verwerking die wordt uitgevoerd wanneer u een dienstverleningscontract bent aangegaan voor de in het Voertuig geleverde Aangesloten Diensten ;</Typography>
        <Typography variant="body1">-         verwerking door regelgevende autoriteiten, wetshandhavingsinstanties of andere gerechtelijke of overheidsinstanties.</Typography>
        <Typography variant="body1" gutterBottom>In deze gevallen zijn wij niet verantwoordelijk voor de verwerking van uw Gegevens die niet onder dit Privacy beleid valt.</Typography>
        
        <Typography variant="h4">12. Gebruik van gegevens voor andere doeleinden</Typography>
        <Typography variant="body1" gutterBottom>Indien wij uw Gegevens anders of voor andere dan de hier vermelde doeleinden moeten verwerken, zult u daarvan specifiek in kennis worden gesteld voordat de verwerking begint.</Typography>
        
        <Typography variant="h4">13. Wijzigingen in het privacy beleid</Typography>
        <Typography variant="body1" gutterBottom>Wij behouden ons het recht voor dit Privacybeleid te allen tijde aan te passen en/of te wijzigen. Wij zullen u van dergelijke aanpassingen/wijzigingen op de hoogte brengen.</Typography>
        
        <Typography variant="h4">14. Definities</Typography>
        <Typography variant="body1">Aangesloten Diensten: verwijst naar het geheel van diensten beschreven in de Algemene Voorwaarden van de Autofabrikant, alsmede de standaard en optionele diensten, indien geactiveerd. </Typography>
        <Typography variant="body1">Autofabrikanten: verwijst afzonderlijk of gezamenlijk naar de volgende entiteiten die optreden als fabrikant van voertuigen: Stellantis Europe S.p.A. Corso Agnelli 200, 10135 Turijn, Italië; Stellantis Auto S.A.S., 2-10 Boulevard de l&apos;Europe, F-78300 Poissy, Frankrijk; Opel Automobile GmbH, Bahnhofsplatz, D-65423 Rüsselsheim am Main, Duitsland.</Typography>
        <Typography variant="body1">Diensten: dit betreft alle diensten die door ons worden geleverd.</Typography>
        <Typography variant="body1">Geaggregeerde Informatie: verwijst naar statistische informatie die uw Persoonsgegevens niet bevat.</Typography>
        <Typography variant="body1">Gegevensverantwoordelijke: de rechtspersoon, overheidsinstantie, dienst of andere entiteit die individueel de doeleinden en middelen voor de verwerking van uw Persoonsgegevens vaststelt.</Typography>
        <Typography variant="body1">Gegevensverwerker: verwijst naar een entiteit die wij inschakelen om uw Persoonsgegevens te verwerken, uitsluitend namens en volgens de schriftelijke instructies van de Gegevensverwerker.</Typography>
        <Typography variant="body1">Partners: betekent derde entiteiten die uw Persoonsgegevens alleen aan ons mogen meedelen nadat zij ons contractueel hebben verzekerd dat zij uw toestemming hebben verkregen of dat zij een andere rechtsgrondslag hebben die hun mededeling/deling van dergelijke gegevens met ons legitimeert (bijvoorbeeld als u een van onze Partners vraagt om een proefrit te boeken, als u een aankoop doet en als u verzoekt om commerciële communicatie te ontvangen). Deze definitie omvat ook de geselecteerde Partners met wie wij uw Gegevens kunnen delen. De Partners kunnen behoren tot de volgende productsectoren: productie, groot- en detailhandel, financiële diensten, bankdiensten, vervoer en opslag, informatie- en communicatiediensten, professionele, wetenschappelijke en technische activiteiten, reisbureaus, bedrijfsondersteunende diensten, artistieke, sportieve, amusements- en entertainmentactiviteiten, activiteiten van ledenorganisaties, diensten van fysieke wellnesscentra, leveranciers van elektriciteit en gas, verhuur, e-mobiliteit en verzekeringsmaatschappijen.</Typography>
        <Typography variant="body1">Persoonsgegevens: alle informatie betreffende een geïdentificeerde of identificeerbare natuurlijke persoon, direct of indirect, alsmede alle informatie die gekoppeld is of redelijkerwijs gekoppeld kan worden aan een bepaalde persoon of een bepaald huishouden. Voor uw gemak zullen wij alle genoemde Persoonsgegevens ook collectief aanduiden als &quot;Gegevens&quot;.</Typography>
        <Typography variant="body1">Unieke identificatiemiddelen: informatie die u uniek identificeert of waardoor u kunt worden geïdentificeerd. Op een voertuig zijn de unieke identificatiemiddelen het kenteken en het voertuigidentificatienummer (VIN).</Typography>
        <Typography variant="body1">Voertuig: verwijst naar een voertuig van een van de merken van de Stellantis Groep.</Typography>
        <Typography variant="body1">Voertuigapparaat: betekent afzonderlijk of gezamenlijk een apparaat waarmee Voertuiggegevens kunnen worden verzameld en het telematicatoestel (en de bijbehorende simkaart) dat op het Voertuig is geïnstalleerd en beter wordt beschreven in de Algemene Voorwaarden van de Autofabrikant.</Typography>
        <Typography variant="body1">Voertuiggegevens: alle technische, diagnostische en realistische gegevens die kunnen worden verzameld via het op het voertuig geïnstalleerde voertuigapparaat (bv. locatie, snelheid en afstanden, tijd dat de motor draait en uitschakelt; als de accukabel is doorgesneden, accudiagnostiek, bewegingen met de sleutel uit, vermoedelijke botsing, alsook diagnostische gegevens zoals, maar niet beperkt tot, olie- en brandstofniveaus, bandenspanning en/of energieverbruik motorstatus). Voertuiggegevens zijn gewoonlijk geaggregeerde informatie, tenzij ze verband houden met Persoonsgegevens die op u betrekking hebben.</Typography>
    </Container>
  );
}

export default PrivacyPolicy;
