import * as React from 'react';

// elements
import { FormControlLabel, FormGroup, Typography, Checkbox } from '@mui/material';

import useLanguage from 'hooks/useLanguage';

// redux
import { useAppDispatch } from 'redux/hooks';
import {
    setSelectedFuelTypes,
    setSelectedTransmissionTypes,
    setSelectedBodyTypes,
} from 'redux/features/vehicleStateSlice';
import { capitalize } from 'helpers/stringHelper';

interface Props {
    id: string;
    label: string;
    options: string[];
    // fieldChanged: (event: React.SyntheticEvent<Element, Event>, value: string[]) => void;
}

export default function Checkboxes({ id, label, options }: Props) {
    const { t } = useLanguage();
    const dispatch = useAppDispatch();

    const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, option: string) => {
        const newSelectedOptions = event.target.checked
            ? [...selectedOptions, option]
            : selectedOptions.filter(selectedOption => selectedOption !== option);
        

        setSelectedOptions(newSelectedOptions);

        switch (id) {
            case 'filter-fuel':
                dispatch(setSelectedFuelTypes({ fuels: newSelectedOptions }));
                break;
            case 'filter-transmission':
                dispatch(setSelectedTransmissionTypes({ gearTypes: newSelectedOptions }));
                break;
            case 'filter-body':
                dispatch(setSelectedBodyTypes({ bodyTypes: newSelectedOptions }));
                break;
        }


        // fieldChanged(event, newSelectedOptions);
    };

    const labelProps = { inputProps: { 'aria-label': label } };

    return (
        <div>
            <FormGroup>
                {options.length > 0 ? (
                    options.map((option, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    {...labelProps}
                                    name={`${id}[]`}
                                    checked={selectedOptions.includes(option)}
                                    onChange={(event) => handleChange(event, option)}
                                />
                            }
                            label={capitalize(t(option))}
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: '1.5rem' },
                                '& .MuiTypography-root': { fontSize: '0.9rem', marginLeft: '0.5rem' },
                            }}
                        />
                    ))
                ) : (
                    <Typography variant='body2'>No options available</Typography>
                )}
            </FormGroup>
        </div>
    );
}
