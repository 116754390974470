import React, { useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';

import useLanguage from 'hooks/useLanguage';

import VehicleCard from "components/common/VehicleCard";
import { Grid, Typography } from "@mui/material";
import Item from '@mui/material/Grid';

import { BounceAnimation } from 'hooks/useAnimations';

import { LuLoader } from "react-icons/lu";

type Props = {
    id: number;
}

export default function AanbodGrid({ id }: Props) {
    const { t } = useLanguage();
    const IsLoading = useAppSelector((state) => state.vehicleStateSlice.isLoading);
    const FilteredVehicles = useAppSelector((state) => state.vehicleStateSlice.filteredVehicles);

    useEffect(() => {
        // console.log(`FilteredVehicles`, FilteredVehicles);

        console.log(`IsLoading changed`, IsLoading);
        
    }, [IsLoading]);

    useEffect(() => {
        // console.log(`FilteredVehicles`, FilteredVehicles);
    }, [FilteredVehicles]);
    
    return (
        <React.Fragment>
            {IsLoading ? (
                <Grid id={id.toString()} container spacing={2} columns={1} sx={{
                    height: '100%',
                }}>
                    <div className='h-full w-full flex flex-col gap-8 justify-center items-center'>
                        <div className='w-40 h-40 rounded-full bg-primary-grey flex justify-center items-center'>
                            {/* <img className="h-1/2 w-1/2" src="/images/no-results.png" alt="no-results" loading='lazy' /> */}
                            <LuLoader className="h-1/2 w-1/2 loading-icon"/>
                        </div>
                        <div className='flex flex-col'>
                            <Typography variant="h5" gutterBottom component="div">
                                {t('Loading Vehicles')}
                            </Typography>
                        </div>
                    </div>
                </Grid>
            ) : (
                (FilteredVehicles.length <= 0 && !IsLoading) ? (
                    <Grid id={id.toString()} container spacing={2} columns={1} sx={{
                        height: '100%',
                    }}>
                        <div className='h-full w-full flex flex-col gap-8 justify-center items-center'>
                            <div className='w-40 h-40 rounded-full bg-primary-grey flex justify-center items-center'>
                                <img className="h-1/2 w-1/2" src="/images/no-results.png" alt="no-results" loading='lazy' />
                            </div>
                            {/* <p className='text-2xl font-bold'>{t('No items found')}</p> */}
                            <div className='flex flex-col'>
                                <Typography variant="h5" gutterBottom component="div">
                                    {t('No items found')}
                                </Typography>
                                <Typography variant="body1" gutterBottom component="div">
                                    {t('Try to remove some filters')}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                ) : (
                    <Grid id={id.toString()} container spacing={2} columns={12}>
                        {FilteredVehicles.map((vehicle, index) => (
                            <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={4}>
                                {/* <BounceAnimation delay={0}> */}
                                <Item>
                                    <VehicleCard hasThumbnailSlider={true} data={vehicle} internalId={vehicle.internal_id} make={vehicle.make} model={vehicle.model} price={vehicle.sell_price} thumbnails={(typeof vehicle.images.image !== undefined) ? vehicle.images.image.slice(0, 3) : []} />
                                </Item>
                                {/* </BounceAnimation> */}
                            </Grid>
                        ))}
                    </Grid>
                )
            )}
        </React.Fragment>
    );
}