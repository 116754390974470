import * as React from 'react';

// elements
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// types
import { FilterMake } from 'redux/features/VehiclesTypes';

// redux
import { useAppDispatch } from 'redux/hooks';
import { setSelectedMakesAndModels } from 'redux/features/vehicleStateSlice';

interface CheckedState {
  [makeName: string]: boolean[];
}

interface SelectModel {
  make: string;
  model: string;
}

interface Props {
  id: string;
  makes: FilterMake[];
}

export default function CheckboxGroup({ id, makes }: Props) {
  const dispatch = useAppDispatch();
  const [checked, setChecked] = React.useState<CheckedState>({});

  const handleParentChange = (makeName: string) => {
    setChecked(prev => {
      const isAllChecked = prev[makeName]?.every(Boolean) || false;
      return { ...prev, [makeName]: prev[makeName].map(() => !isAllChecked) };
    });
  };

  const handleChildChange = (makeName: string, modelIndex: number) => {
    setChecked(prev => {
      const newChecked = prev[makeName].map((state, index) => index === modelIndex ? !state : state);
      return { ...prev, [makeName]: newChecked };
    });
  };

  React.useEffect(() => {
    const initialChecked: CheckedState = {};
    makes.forEach(make => {
      initialChecked[make.name] = Array(make.models.length).fill(false);
    });
    setChecked(initialChecked);
  }, [makes]);



  const handleChange = (selectedModels: SelectModel[]) => {
    dispatch(setSelectedMakesAndModels({
      makes: selectedModels.map(item => item.make),
      models: selectedModels.map(item => item.model)
    }));
  };


  React.useEffect(() => {
    handleChange(Object.keys(checked).reduce((acc, makeName) => {
      const make = makes.find(make => make.name === makeName);
      if (!make) return acc;
      return acc.concat(make.models.reduce((acc, model, index) => {
        if (checked[makeName]?.[index]) {
          acc.push({ make: makeName, model: model });
        }
        return acc;
      }, [] as SelectModel[]));
    }, [] as SelectModel[]));
  }, [checked, makes]);

  return (
    <div id={id}>
      {makes.map(make => (
        <Box key={make.name}>
          <FormControlLabel
            label={make.name}
            control={
              <Checkbox
                onChange={() => handleParentChange(make.name)}
                checked={checked[make.name]?.every(Boolean) || false}
                indeterminate={checked[make.name] ? checked[make.name].some(Boolean) && !checked[make.name].every(Boolean) : false}
              />
            }
          />
          <Box
            sx={{ display: checked[make.name]?.some(Boolean) ? 'flex' : 'none', flexDirection: 'column', ml: 3 }}
          >
            {make.models.map((model, index) => (
              <FormControlLabel
                key={model}
                label={model}
                control={
                  <Checkbox
                    checked={checked[make.name]?.[index] || false}
                    onChange={() => handleChildChange(make.name, index)}
                  />
                }
              />
            ))}
          </Box>
        </Box>
      ))}
    </div>
  );
}
