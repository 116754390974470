import React from 'react'
import { useInView, useSpring, animated } from '@react-spring/web'
import { buildInteractionObserverThreshold } from 'helpers/treshold'

// export multiple animations

export const BounceAnimation = (Props: any) => {
    const [bounceRef, isBounceInView] = useInView({
        rootMargin: '-5% 0px -5% 0px',
        amount: buildInteractionObserverThreshold(Props.amount ?? 0.5),
    })
    
    const bounceStyles = useSpring({
        to: async (next: any) => {
            await next({
                scale: isBounceInView ? 1 : 0,
                opacity: isBounceInView ? 1 : 0,
                delay: Props.delay,
            })
        },
        config: {
            tension: Props.tension ? Props.tension : 80,
            friction: Props.friction ? Props.friction : 13,
        }
    })

    return (
        // <animated.div className={Props.extraclasses ?? '' + ' animated-div'} ref={bounceRef} style={bounceStyles}>
        //     {Props.children}
        // </animated.div>

        // set opacity to 0 to hide the element when it is not in view
        <animated.div className={Props.extraclasses ?? '' + ' animated-div'} ref={bounceRef} style={{ opacity: 0, ...bounceStyles }}>
            {Props.children}
        </animated.div>
    );
}
    
export const MoveFadeInAnimation = (Props: any) => {
    const [moveFadeInRef, isMoveFadeInView] = useInView({
        rootMargin: '-5% 0px -5% 0px',
        amount: buildInteractionObserverThreshold(Props.amount ?? 0.5),
    })

    let distance = 100;
    if (Props.distance !== undefined) {
        if (Props.distance === 'xs') {
        distance = 50;
        } else if (Props.distance === 'sm') {
        distance = 100;
        } else if (Props.distance === 'md') {
        distance = 150;
        } else if (Props.distance === 'lg') {
        distance = 200;
        } else if (Props.distance === 'xl') {
        distance = 250;
        } else if (Props.distance === 'xxl') {
        distance = 300;
        }
    }

    let translatePropertyFrom = `translateX(0px)`;
    let translatePropertyTo = `translateX(${distance}px)`;
    if (Props.direction !== undefined) {
        if (Props.direction === 'up') {
        translatePropertyFrom = `translateY(0px)`;
        translatePropertyTo = `translateY(${distance}px)`;
        } else if (Props.direction === 'down') {
        translatePropertyFrom = `translateY(0px)`;
        translatePropertyTo = `translateY(-${distance}px)`;
        } else if (Props.direction === 'right') {
        translatePropertyFrom = `translateX(0px)`;
        translatePropertyTo = `translateX(-${distance}px)`;
        }
    }

    const moveFadeInStyles = useSpring({
        to: async (next: any) => {
        await next({
            transform: isMoveFadeInView ? translatePropertyFrom : translatePropertyTo,
            opacity: isMoveFadeInView ? 1 : 0,
            delay: Props.delay,
        })
        },
        config: {
        tension: 120,
        friction: 14,
        }
    })

    return (
        <animated.div className={Props.extraclasses + ' animated-div' ?? 'animated-div'} ref={moveFadeInRef} style={{ opacity: 0, ...moveFadeInStyles }}>
            {Props.children}
        </animated.div>
    );
}