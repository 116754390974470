import React from 'react';

import { Container, ListItemButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import { List, ListItem, ListItemText } from '@mui/material';

import Routes from "routes/routes";
import { Link } from 'react-router-dom';

import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";

import useLanguage from 'hooks/useLanguage';

import { MoveFadeInAnimation } from 'hooks/useAnimations';

const Footer = () => {
    const { activeLanguage, t } = useLanguage();

    const listItems1 = Routes;

    return (
        <>
            <section className="pb-32 flex bg-primary-grey">
                <Container>
                    {/* <hr className='border-white' /> */}
                    <Grid container spacing={3} className='mt-10'>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Item>
                                <List className="pb-0 m-0">
                                    <Typography variant="h6" className="px-0 pb-2">
                                        {t("General")}
                                    </Typography>

                                    {listItems1.map((item, index) => (
                                        item.showInMenu && item.showInMenu === true ?  (
                                        <ListItem key={index} className="p-0 m-0 inline-block">
                                            <MoveFadeInAnimation delay={100 * index}>
                                                {item.path ? (                                                
                                                    <ListItemButton component={Link} to={item.path} aria-label={item.displayText} sx={{
                                                        paddingX: 1,
                                                        display: "inline-block"
                                                    }}>
                                                        {t(item.displayText)}
                                                    </ListItemButton>
                                                ) : (
                                                    <ListItemText className='px-0 py-0'
                                                        primary={item.displayText}
                                                    />
                                                )}
                                            </MoveFadeInAnimation>
                                        </ListItem>
                                        ) : null
                                    ))}
                                </List>
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <List>
                                <Typography variant="h6" className="px-0 pb-1">
                                    {t("Follow us on:")}
                                </Typography>
                                <div className='flex gap-2 items-center justify-left pt-3'>
                                    <Link to="//www.facebook.com/kegelsturnhout">
                                        <FaFacebookF size={22} className='bg-[#3b5998] fill-white rounded-md pt-1 my-auto transition hover:scale-105' target='blank'/>
                                    </Link>
                                    <Link to="//www.instagram.com/kegels.turnhout/" target='blank'>
                                        <FaInstagram size={26} className=' fill-black p-0 my-auto transition hover:scale-105'/>
                                    </Link>
                                </div>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Item>
                                <List>
                                    <Typography variant="h6" className="px-0 pb-1">
                                        {t("Contact")+":"}
                                    </Typography>
                                    <MoveFadeInAnimation delay={100}>
                                        <ListItem className="p-0 m-0 inline-block">
                                            <ListItemButton key={0} component={Link} to={'tel:3214450311'} aria-label={t('Tel')+": +3214450311"} sx={{
                                                paddingX: 1,
                                                display: "inline-block"
                                            }}>
                                                {t('Phone')+": +32 14 45 03 11"}</ListItemButton>
                                        </ListItem>
                                    </MoveFadeInAnimation>
                                    <MoveFadeInAnimation delay={300}>
                                        <ListItem className="p-0 m-0 inline-block">
                                            <ListItemButton key={1} component={Link} to={'mailto:info@kegelsturnhout.be'} aria-label={t('Email')+": info@kegelsturnhout.be"} sx={{
                                                paddingX: 1,
                                                display: "inline-block"
                                            }}>
                                                {t('Email')+": info@kegelsturnhout.be"}
                                            </ListItemButton>
                                        </ListItem>
                                    </MoveFadeInAnimation>
                                </List>
                            </Item>
                        </Grid>
                    </Grid>
                </Container>    
            </section>
            <section className="flex bg-grey">
                <Container>
                    <Grid container spacing={3} className='h-full py-3'>
                        <Grid item xs={12} sm={12} md={12} lg={12} className='lg:flex gap-2 justify-center items-center text-sm'>
                            <Typography className="my-auto font-normal text-sm">
                                © Copyright 2023 Kegels nv (BE 0437.242.049) {t("All rights reserved")} |
                            </Typography>
                            <Link className='underline transition hover:text-black hover:scale-105' to={`${activeLanguage}/privacy-policy`}>
                                {t("Privacy policy")}
                            </Link>
                            <span className='invisible md:visible'> | </span>
                            <Link className='underline transition hover:text-black hover:scale-105' to={`${activeLanguage}/legal-provisions`}>
                                {t("Legal Notices")}
                            </Link>
                            {/* <span> | </span> */}
                            {/* <Link className='underline transition hover:text-black hover:scale-105' to={`${activeLanguage}/cookies`}>
                                {t("Cookies")}
                            </Link> */}
                        </Grid>
                    </Grid>
                </Container>    
            </section>
        </>
    );
}
 
export default Footer;