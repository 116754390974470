import { XMLParser } from "fast-xml-parser";
import { Vehicle } from "redux/features/VehiclesTypes";

const parser = new XMLParser();

const vehicleService = {
    async getVehicles(): Promise<Vehicle[]> {
        const response = await fetch(`https://kegelsturnhout.be/vehicles.php`);
        let responseText = await response.text();
        
        responseText = responseText.replace('<?xml version="1.0" encoding="UTF-8"?>', '')
        const jsonObject = parser.parse(responseText);
        
        if (typeof jsonObject.vehicles !== "undefined") {
            if (typeof jsonObject.vehicles.vehicle !== "undefined") {
                return jsonObject.vehicles.vehicle;
            } else {
                return [];
            }
        } else {
            return []
        }
    },
}


export default vehicleService;