import { combineReducers, configureStore } from "@reduxjs/toolkit";
import vehicleStateSlice from "redux/features/vehicleStateSlice";


const reducer = combineReducers({
  vehicleStateSlice
})

export const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;