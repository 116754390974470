import { useEffect } from "react";

type ObjectType = {
  title: string,
  description: string,
  keywords: string,
}

export default function useMeta(metaObject: ObjectType) {
  const defaultTitle = 'This is the default title.';
  const defaultDescription = 'Lorem ipsum dolar.';
  const defaultKeywords = 'default keywords';
  
  useEffect(() => {
    document.title = metaObject.title || defaultTitle;
    document.querySelector('meta[name="title"]')!.setAttribute('content', metaObject.title || defaultTitle);
    document.querySelector('meta[name="description"]')!.setAttribute('content', metaObject.description || defaultDescription);
    document.querySelector('meta[name="keywords"]')!.setAttribute('content', metaObject.keywords || defaultKeywords);
  }, [defaultTitle, metaObject.title, defaultDescription, metaObject.description, defaultKeywords, metaObject.keywords]);

  return null;
}