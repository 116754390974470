import { AppBar, Box, Grid, List, ListItemButton, Stack } from "@mui/material";
import React from "react";
import TopbarItem from "components/common/TopbarItem";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Routes from "routes/routes";

import { Container } from "@mui/material";

import { Link } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import LanguageSwitcher from "components/elements/LanguageSwitcher";
import { BounceAnimation } from '../../hooks/useAnimations';
import UseScrollToAnchor from "hooks/ScrollToAnchor";

import useLanguage from "hooks/useLanguage";


type Props = {
  children?: any;
  window?: () => Window;
  collection?: any;
}

const Topbar = (props: Props) => {
  const { activeLanguage, t } = useLanguage();

  const ActionItems = [
    {
      icon: "LocalPhoneIcon",
      path: "tel:32 14 450311",
      displayText: "Bel ons",
    },
    {
      icon: "MailIcon",
      path: "mailto:info@kegelsturnhout.be",
      displayText: "Mail us"
    },
    {
      icon: "RoomIcon",
      path: "https://maps.app.goo.gl/4bk7zcpYJ9x8vkyr7",
      displayText: "Visit us"
    },
  ];

  // function HideOnScroll(props: Props) {
  //   const { children, window } = props;
  //   const trigger = useScrollTrigger({
  //     target: window ? window() : undefined,
  //   });
    
  //   return (
  //     <Slide appear={false} direction="down" in={!trigger} timeout={300}>
  //       {children}
  //     </Slide>
  //   );
  // }

  // create a a click toggle func for mobile-menu-trigger
  const toggleMobileMenu = (event: React.MouseEvent<HTMLElement>) => {
    const mobileMenu = document.getElementById('mobile-menu') as HTMLElement;
    const mobileMenuIcon = document.getElementById('mobile-menu-trigger-menu') as HTMLElement;
    const mobileMenuCloseIcon = document.getElementById('mobile-menu-trigger-close') as HTMLElement;
    //Get all .mobile-menu-trigger elements
    

    // Check if menu is already open
    if (mobileMenu.classList.contains('block')) {
      mobileMenuIcon.classList.remove('hidden');
      mobileMenuCloseIcon.classList.add('hidden');

      mobileMenu.classList.remove('slideDown');
      mobileMenu.classList.add('slideUp');
      
      
      
      //set timeout to allow animation to finish
      setTimeout(() => {
        mobileMenu.classList.remove('block');
        mobileMenu.classList.add('hidden');
      }, 200);

    }
    else {
      mobileMenuIcon.classList.add('hidden');
      mobileMenuCloseIcon.classList.remove('hidden');

      mobileMenu.classList.remove('slideUp');
      mobileMenu.classList.add('slideDown');

      setTimeout(() => {
        mobileMenu.classList.remove('hidden');
        mobileMenu.classList.add('block');
      }, 200);
    }

  };

  UseScrollToAnchor();

  return (
      <AppBar
        position="fixed"
        sx={{
          boxShadow: "unset",
          backgroundColor: "transparent",
          Height: "65px",
          display: "flex",

        }}
        className="bg-primary-grey"
      >
        <Container className="min-h-[65px] h-[65px]">
          <Grid container direction="row" columnSpacing={0} mt={0} className="relative h-full m-auto justify-between">
              <Grid item container xs={6} md={4} className="relative h-full my-auto items-center">
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={toggleMobileMenu}
                  className="mobile-menu-trigger z-10 visible md:invisible m-auto absolute aspect-square"
                  color="inherit"
                >
                  <CloseIcon id="mobile-menu-trigger-close" className="hidden mobile-menu-trigger fill-black" />
                  <MenuIcon id="mobile-menu-trigger-menu" className="mobile-menu-trigger fill-black" />
                  
                </IconButton>
                
                <Link to={`${activeLanguage}/#root`} className="pl-14 ml-0 md:pl-0 m-auto">
                  <img className="max-h-[60px] w-20 sm:w-30 md:h-14 md:w-auto" src="/images/logo-kegels.png" alt="logo-banner" loading='lazy' />
                </Link>
                  
              </Grid>
                        
              <Grid item container xs={6} justifyContent="flex-end">
                <Grid flexGrow={1}>
                  <List component={Stack} direction="row" justifyContent="flex-end">
                      {Routes.map((item, index) => (
                        item.showInMenu && item.showInMenu === true ?  (
                          item.displayText && !item.index ?  (
                            (
                              <div key={index} className="hidden md:block" >
                                <TopbarItem path={item.path} displayText={item.displayText} icon="" isExternal={false} />
                              </div>
                            )
                            ) : null
                          ) : null
                      ))}
                      {ActionItems.map((item, index) => (
                        item.icon ?  (
                          (
                            <div key={index} className="block" >
                              <TopbarItem path={item.path} displayText={item.displayText} icon={item.icon} isExternal={true} key={index} /> 
                            </div>
                          )
                        ) : null
                      ))}
                  </List>
                </Grid>
              </Grid>
          </Grid>

          <div id="mobile-menu" className="bg-primary-grey hidden rounded-2xl p-4 z-20 relative">
              <div>
                {Routes.map((item, index) => (
                  item.showInMenu && item.showInMenu === true ?  (
                    item.displayText && !item.index ?  (
                      (
                        <div key={index} onClick={toggleMobileMenu}>
                          <TopbarItem path={item.path}  icon="" displayText={item.displayText} isExternal={false}/>
                        </div>
                      )
                    ) : null
                  ) : null
                ))}
              </div>
          </div>

          <div id="left-menu-container" />
      </Container>
    </AppBar>
  )
};

export default Topbar;