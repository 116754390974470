import React, { useState, useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import VehicleCard from 'components/common/VehicleCard';
import { Grid, IconButton, Box } from '@mui/material';
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import useSwipe from 'helpers/useSwipe';
import useDrag from 'helpers/useDrag';


type Props = {
    id: string;
    hasNavigationArrows?: boolean;
}

export default function BannerSlider({ id, hasNavigationArrows = false}: Props) {
    // const Vehicles = useAppSelector((state) => state.vehicleStateSlice.vehicles).slice(0, 8);

    const items = [
        '/images/garage-kegels-header.jpg',
        '/images/garage-kegels-header-1.jpg',
        // '/images/garage-kegels-header.jpg',
    ];

    const [perPage, setPerPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);

    const handleNextPage = () => {
        if (currentPage >= items.length / perPage - 1) {
            setCurrentPage(0);
        } else {
            setCurrentPage(currentPage => Math.min(currentPage + 1, Math.ceil(items.length / perPage) - 1));
        }
    };

    const handlePrevPage = () => {
        if (currentPage <= 0) {
            setCurrentPage(items.length / perPage - 1);
        } else {
            setCurrentPage(currentPage => Math.max(currentPage - 1, 0));
        }
        // setCurrentPage(currentPage => Math.max(currentPage - 1, 0));
    };

    const swipeHandlers = useSwipe({ onSwipedLeft: () => handleNextPage(), onSwipedRight: () => handlePrevPage() });
    const dragHandlers = useDrag({ onDragLeft: (e) => handlePrevPage(), onDragRight: (e) => handleNextPage() });

    const combinedHandlers = {
        ...swipeHandlers,
        ...dragHandlers,
    };

    const startIndex = currentPage * perPage;
    const endIndex = Math.min(startIndex + perPage, items.length);
    const displayedItems = items.slice(startIndex, endIndex);

    const absoluteStyles = {
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 999,
        backgroundColor: "rgba(0,0,0,0.5)",
        color: "white",
        position: "absolute",
        borderRadius: "50%",
        cursor: "pointer",
        ":hover": {
            backgroundColor: "rgba(0,0,0,0.5)",
        },
        ":disabled": {
            backgroundColor: "rgba(0,0,0,0.4)",
            color: "rgba(255,255,255,0.5)",
        },
    }

    return (
        <Box className="relative mx-auto w-full" sx={{ flexGrow: 1 }}>
            {(hasNavigationArrows) && (
                <IconButton sx={{
                        ...absoluteStyles,
                        left: "-4%",
                    }}
                    aria-label="vorige" disabled={currentPage === 0} onClick={handlePrevPage}
                >
                    <NavigateBeforeIcon />
                </IconButton>
            )}

            {displayedItems.map((vehicle, index) => (
                <div {...combinedHandlers} key={index} className='aspect-[19/6] w-full select-none cursor-grab'>
                    <img className="pointer-events-none select-none w-full h-full object-cover" src={vehicle} alt="" />
                </div>
            ))}

            {(hasNavigationArrows) && (
                <IconButton sx={{
                        ...absoluteStyles,
                        right: "-4%",
                    }}
                    aria-label="volgende" disabled={currentPage >= items.length / perPage - 1} onClick={handleNextPage}
                >
                    <NavigateNextIcon />
                </IconButton>
            )}
        </Box>
    );
}
